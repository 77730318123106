const host = "https://www.ggchats.com";

// get请求
export function getRequest(url, data, successFunc){
    url = host + url + "?" + getParams(data);
    let headers = {
        'Accept': "application/json; charset=utf-8",
    };
    fetch(url, {
        // mode: 'no-cors',
        credentials: "omit",
        method: "get",
        headers: headers,
    }).then((response)=>{
        if(response.status <= 400){
            response.json().then((data)=>{
                successFunc(data);
            });
        }else{
            let data = {
                code: 400,
                message: "invalid request",
            };
            successFunc(data);
        }
    }).catch((err)=>{
        console.log("server busy, please try again later");
    });
}

function getParams(obj){
    let params = "";
    for(let k in obj){
        params = params + k + "=" + obj[k] + "&";
    }
    if(params.length !== 0){
        params = params.substr(0, params.length-1);
    }
    return params;
}