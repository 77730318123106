import './App.css';
import {Component} from "react";
import {getRequest} from "./common/request";

class App extends Component {
    constructor(props) {
        super(props);
        const search = window.location.search; // 获取 URL 中的查询字符串，如 "?foo=bar"
        const params = new URLSearchParams(search); // 使用 URLSearchParams 解析查询字符串
        // 获取参数
        const date = params.get('date');
        const chatId = params.get('chat_id');
        this.state = {
            date: date,
            chatId: chatId,
            loading: true,
            list: [],
            deposit_amount: 0,
            deposit_count: 0,
            withdraw_amount: 0,
            withdraw_count: 0,
        };
        this.getOrderList = this.getOrderList.bind(this);
    }
    componentDidMount() {
        this.getOrderList();
    }
    // 获取订单列表
    getOrderList(){
        getRequest("/bot/order/list", {date: this.state.date, chat_id: this.state.chatId}, (data)=>{
            if(data.code === 0){
                this.setState({
                    loading: false,
                    list: data.data.order_list,
                    deposit_amount: data.data.deposit_amount,
                    deposit_count: data.data.deposit_count,
                    withdraw_amount: data.data.withdraw_amount,
                    withdraw_count: data.data.withdraw_count,
                });
            }
        })
    }
    render(){
        return  (
            <div id="app">
                <div className="header">得胜集团{this.state.date}订单列表</div>
                <div className="row">
                    <div className="time title">时间</div>
                    <div className="operator title">操作人</div>
                    <div className="amount title">金额</div>
                    <div className="time title">时间</div>
                    <div className="operator title">操作人</div>
                    <div className="amount title">金额</div>
                    {
                        this.state.list.map((item, i)=>{
                            return (
                                <>
                                    <div className="time">{item.created_at}</div>
                                    <div className="operator">{item.nickname}{item.username === "" ? null : `(${item.username})` }</div>
                                    {
                                        item.type === 1 ?
                                            <div className="amount add">+{item.amount}</div> :
                                            <div className="amount sub">-{item.amount}</div>
                                    }
                                </>
                            )
                        })
                    }
                </div>
                <div className="statistics">
                    总入款{this.state.deposit_count}笔, 共计<span className="amount">{this.state.deposit_amount}</span>元;
                    总下发{this.state.withdraw_count}笔, 共计<span className="amount">{this.state.withdraw_amount}</span>元;
                    未下发<span className="amount">{this.state.deposit_amount - this.state.withdraw_amount}</span>元
                </div>
                {
                    this.state.loading ? null :
                        <div id="complete"></div>
                }
            </div>
        )
    }
}

export default App;

